import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Html5Qrcode } from "html5-qrcode";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import common from '../common';
import state from "../state";

const iconLink = <svg xmlns="http://www.w3.org/2000/svg" className="block my-auto h-8 w-8" width="20.513" height="30" viewBox="0 0 20.513 30">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_80" data-name="Rectangle 80" width="20.513" height="30" fill="none" stroke="#fff" strokeWidth="1" />
    </clipPath>
  </defs>
  <g id="Group_69" data-name="Group 69" transform="translate(2.792 3)">
    <line id="Line_17" data-name="Line 17" y2="10" transform="translate(2.208 5.25)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
    <g id="Group_68" data-name="Group 68" transform="translate(-2.792 -3)">
      <g id="Group_67" data-name="Group 67" clipPath="url(#clip-path)">
        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.308" cy="2.308" r="2.308" transform="translate(2.5 3.923)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
        <circle id="Ellipse_11" data-name="Ellipse 11" cx="2.308" cy="2.308" r="2.308" transform="translate(11.731 3.923)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
        <circle id="Ellipse_12" data-name="Ellipse 12" cx="2.308" cy="2.308" r="2.308" transform="translate(2.5 21.462)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
        <path id="Path_79" data-name="Path 79" d="M15.167,11v1.5c0,2.209-1.493,4-3.333,4h-2.5C7.492,16.5,6,18.291,6,20.5V24" transform="translate(-1.16 -2.5)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
      </g>
    </g>
  </g>
</svg>


const iconItem = <svg xmlns="http://www.w3.org/2000/svg" width="32.739" height="29.107" viewBox="0 0 32.739 29.107">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_87" data-name="Rectangle 87" width="32.739" height="29.107" transform="translate(-4 -4)" fill="none" />
    </clipPath>
  </defs>
  <g id="Group_139" data-name="Group 139" transform="translate(-237 -669.946)">
    <rect id="Rectangle_115" data-name="Rectangle 115" width="27" height="23" transform="translate(240 673)" fill="#fff" />
    <g id="Group_83" data-name="Group 83" transform="translate(241 673.946)" clipPath="url(#clip-path)">
      <rect id="Rectangle_86" data-name="Rectangle 86" width="10.864" height="10.864" transform="translate(2.827 0.464)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path id="Path_89" data-name="Path 89" d="M36.147,21.768a7.1,7.1,0,1,1,7.1,7.1,7.1,7.1,0,0,1-7.1-7.1" transform="translate(-26.078 -10.58)" fill="#fff" />
      <path id="Path_92" data-name="Path 92" d="M7.1,0A7.1,7.1,0,1,1,0,7.1,7.1,7.1,0,0,1,7.1,0Z" transform="translate(10.069 4.085)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
      <path id="Path_90" data-name="Path 90" d="M9.6,24.776,1.664,38.518H17.532Z" transform="translate(-1.201 -17.874)" fill="#fff" />
      <path id="Path_91" data-name="Path 91" d="M9.6,24.776,1.664,38.518H17.532Z" transform="translate(-1.201 -17.874)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </g>
</svg>

const iconContainer = <svg xmlns="http://www.w3.org/2000/svg" width="20.773" height="23.733" viewBox="0 0 20.773 23.733">
  <g id="Group_140" data-name="Group 140" transform="translate(-3640.113 1838.846)">
    <line id="Line_35" data-name="Line 35" y2="10" transform="translate(3650.5 -1826.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_36" data-name="Line 36" y2="10" transform="translate(3659.5 -1832.893)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_37" data-name="Line 37" y2="10" transform="translate(3641.5 -1832.893)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_38" data-name="Line 38" x2="9" y2="6" transform="translate(3641.5 -1822.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_39" data-name="Line 39" x1="9" y2="6" transform="translate(3650.5 -1822.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_40" data-name="Line 40" x2="9" y2="5.393" transform="translate(3641.5 -1832.893)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_41" data-name="Line 41" y1="4.607" x2="9" transform="translate(3641.5 -1837.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_42" data-name="Line 42" x1="9" y1="4.488" transform="translate(3650.5 -1837.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
    <line id="Line_43" data-name="Line 43" y1="5" x2="9" transform="translate(3650.5 -1832.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
  </g>
</svg>

const iconLocation = <svg className="h-6 w-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28.208">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_52" data-name="Rectangle 52" width="24" height="28.208" transform="translate(-2 -2)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="1" />
    </clipPath>
  </defs>
  <g id="Group_66" data-name="Group 66" transform="translate(2 2)">
    <g id="Group_27" data-name="Group 27" clipPath="url(#clipPath)">
      <path id="Path_23" data-name="Path 23" d="M17.181,3.778l.052.052a9.509,9.509,0,0,1,0,13.412L10.5,24,3.767,17.242a9.508,9.508,0,0,1,0-13.412l.052-.052A9.424,9.424,0,0,1,17.181,3.778Z" transform="translate(-0.5 -0.5)" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
      <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.49" cy="2.5" rx="2.49" ry="2.5" transform="translate(7.51 7.5)" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
    </g>
  </g>
</svg>

export default function ScanPage() {
  const { itemId } = useParams()
  const [scanning, setScanning] = useState(false)
  const [linking, setLinking] = useState(false)
  const [slotOne, setSlotOne] = useState("")
  const [slotTwo, setSlotTwo] = useState("")
  const [lastDetected, setLastDetected] = useState("")
  const [linkSuccess, setLinkSuccess] = useState(false)
  const [flash, setFlash] = useState(false)
  const [scanner, setScanner] = useState<Html5Qrcode>()
  const [linkItem] = useMutation(common.q.LINK_ITEM);
  const navigate = useNavigate()

  const stateSlotOne = useReactiveVar(state.slot1)
  const stateSlotTwo = useReactiveVar(state.slot2)

  // repopulate local state
  if (slotOne !== stateSlotOne && slotOne === "" && stateSlotOne !== "") {
    setSlotOne(stateSlotOne)
  }
  if (slotTwo !== stateSlotTwo && slotTwo === "" && stateSlotTwo !== "") {
    setSlotTwo(stateSlotTwo)
  }

  const slotOneResponse = useQuery(common.q.ITEM_QUERY, { variables: { id: slotOne } })
  const slotTwoResponse = useQuery(common.q.ITEM_QUERY, { variables: { id: slotTwo } })

  const slotOneFilled = slotOne !== ""
  const slotTwoFilled = slotTwo !== ""

  const s1Exists = !slotOneResponse.loading && slotOneResponse.data !== undefined && slotOneResponse.data.item != null
  const s2Exists = !slotTwoResponse.loading && slotTwoResponse.data !== undefined && slotTwoResponse.data.item != null

  useEffect(() => {
    if (slotOneFilled && slotOneResponse.called && !slotOneResponse.loading && slotOneResponse.error !== undefined && slotOneResponse.error != null) {
      if (slotOneResponse.error.message.includes("no rows") && slotOne !== "") {
        state.slot1(slotOne)
        navigate(`/items/${slotOne}/edit`)
      }
    }
    if (slotTwoFilled && slotTwoResponse.called && !slotTwoResponse.loading && slotTwoResponse.error !== undefined && slotTwoResponse.error != null) {
      if (slotTwoResponse.error.message.includes("no rows")) {
        state.slot2(slotTwo)
        navigate(`/items/${slotTwo}/edit`)
      }
    }
  })

  const i4t = (type: string): JSX.Element => {
    switch (type) {
      case "item":
        return iconItem
      case "location":
        return iconLocation
      case "container":
        return iconContainer
      default:
        return iconItem
    }
  }

  let s1Image = <span></span>
  let s1Name = <span></span>
  let s1Icon = iconItem
  let s1Type = ""
  if (s1Exists) {
    s1Image = <img className="rounded-lg" alt="" src={common.imageOrEmpty(slotOneResponse.data.item.detail.photo)}></img>
    s1Name = slotOneResponse.data.item.detail.name
    s1Icon = i4t(slotOneResponse.data.item.type)
    s1Type = slotOneResponse.data.item.type
  }

  let s2Image = <span></span>
  let s2Name = <span></span>
  let s2Icon = <div className="h-6 mt-1"></div>
  let s2Type = ""
  if (s2Exists) {
    s2Image = <img className="rounded-lg" alt="" src={common.imageOrEmpty(slotTwoResponse.data.item.detail.photo)}></img>
    s2Name = slotTwoResponse.data.item.detail.name
    s2Icon = i4t(slotTwoResponse.data.item.type)
    s2Type = slotTwoResponse.data.item.type
  }

  useEffect(() => {
    const doFlash = () => {
      setFlash(true)
      setTimeout(() => {
        setFlash(false)
      }, 175)
    }
    // SetPrimaryImage
    if (lastDetected !== "") {
      const alreadyFilled = (lastDetected === slotOne || lastDetected === slotTwo)

      if (!alreadyFilled) {
        if (!slotOneFilled) {
          doFlash()
          setLinkSuccess(false)
          setSlotOne(lastDetected)
          state.slot1(lastDetected)
        } else if (!slotTwoFilled) {
          doFlash()
          setLinkSuccess(false)
          setSlotTwo(lastDetected)
          state.slot2(lastDetected)
        }
      }
    }
  }, [lastDetected, slotOne, slotTwo, slotOneFilled, slotTwoFilled]);

  const resetSlots = () => {
    state.slot1("")
    state.slot2("")
    setLastDetected("")
    setSlotOne("")
    setSlotTwo("")
  }

  let canLink = false
  let linkMessage = ""
  if (s1Exists) {
    switch (s1Type) {
      case "item":
        linkMessage = "Scan a second code to link this Item to a Container or Location."
        break
      case "container":
        linkMessage = "Scan a second code to link this Container to an Item or Location."
        break
      case "location":
        linkMessage = "Second a second code to link this Location to an Item or Container."
        break
    }
  }

  if (s1Exists && s2Exists && !linkSuccess) {
    const s1t = s1Type
    const s2t = s2Type
    switch (s1t) {
      case "item":
        switch (s2t) {
          case "item":
            linkMessage = "Items must be linked to Containers or Locations, not to other Items."
            break
          case "container":
          case "location":
            canLink = true
            linkMessage = ""
            break
        }
        break
      case "container":
        switch (s2t) {
          case "container":
            linkMessage = "Containers must be linked to Items or Locations, not to other Containers."
            break
          case "item":
          case "location":
            canLink = true
            break
        }
        break
      case "location":
        switch (s2t) {
          case "location":
            linkMessage = "Locations must be linked to Items or Containers, not to other Locations."
            break
          case "container":
          case "item":
            canLink = true
            break
        }
        break
    }
  }

  if (canLink) {
    linkMessage = "Ready to link."
  }


  async function HandleLink() {
    const firstItemId = slotOneResponse.data.item.id
    const secondItemId = slotTwoResponse.data.item.id
    setLinking(true)
    await linkItem({ variables: { firstItemId, secondItemId } }).then(() => {
      setLinkSuccess(true)
      setLinking(false)
      resetSlots()
    });
  }

  const scanOnly = !(slotOneFilled || slotTwoFilled)

  useEffect(() => {
    const videos = document.getElementsByTagName("video")

    const tweakClasses = () => {
      if (videos.length > 0 && !videos.item(0)?.classList.contains("rounded-lg")) {
        videos.item(0)?.classList.add("rounded-lg")
        videos.item(0)?.classList.add("object-cover")
      }
    }

    setTimeout(tweakClasses, 1000)
    setTimeout(tweakClasses, 1500)
    setTimeout(tweakClasses, 3000)

    if (!scanning) {
      if (scanner == null) {
        setScanner(new Html5Qrcode("reader"))
        return
      }
      const html5QrCode: Html5Qrcode = scanner
      const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
        // alert(decodedText)
        setLastDetected(decodedText)
      };
      const config = {
        fps: 20,
        // formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
        aspectRatio: common.isDeployed ? undefined : 0.75
      };
      let doStart = () => {
        html5QrCode.start({ facingMode: common.isDeployed ? "environment" : "user" }, config, qrCodeSuccessCallback, (e) => {
          //suppress individual read failures - expected
        }).then(() => {
          setScanning(true)
        })
      }
      if (html5QrCode.isScanning) {
        html5QrCode.stop().then(doStart)
      } else {
        doStart()
      }
    }
  }, [scanner, setScanning, scanning])

  useEffect(() => {
    if (itemId !== undefined && itemId !== lastDetected) {
      setLastDetected(itemId)
      navigate("/scan")
    }
  }, [itemId, lastDetected, navigate])

  return (
    <div className="max-w-md h-full flex-col w-screen">
      <div id="flash" className={`pointer-events-none w-full h-screen absolute z-50 bg-white t-0 transition-opacity duration-200  ${flash ? "opacity-100" : "opacity-0"}`}>
      </div>
      <section className="">
        <div className="w-full col-span-2 items-center px-3 relative grow min-h-full">
          <div id="reader" className="rounded-lg" />
          <div className={`mx-auto my-auto absolute w-3/5 aspect-square bg-black bg-opacity-40 inset-0 rounded-full ${scanOnly ? "" : "hidden"}`}></div>
          <div className={`z-10 mx-auto my-auto items-center w-1/2 aspect-square absolute inset-1/4  ${scanOnly ? "" : "hidden"}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 259 259">
              <g id="Group_64" data-name="Group 64" transform="translate(-0.333 -0.333)" clipPath="url(#clipPath)">
                <path id="Path_75" data-name="Path 75" d="M120.452,91.952V1H29.5" transform="translate(133.407 4.808)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_76" data-name="Path 76" d="M91.952,1H1V91.952" transform="translate(4.808 4.808)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_77" data-name="Path 77" d="M1,29.5v90.952H91.952" transform="translate(4.808 133.407)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                <path id="Path_78" data-name="Path 78" d="M29.5,120.452h90.952V29.5" transform="translate(133.407 133.407)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              </g>
            </svg>
          </div>
          <div className={`absolute text-white text-center text-3xl top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] ${scanOnly ? "" : "hidden"}`}>
            <p>Scan a code</p>
            <p>to get started!</p>
          </div>
        </div>
      </section>

      {
        scanOnly && linkSuccess ? <div className="mt-8 h-12 text-center text-2xl mx-4 animate-bounce my-auto"><span className="text-bold font-bold text-[#087F79]">Successful link!</span> Scan a code to begin linking again.</div>
          : ""
      }

      <section id="commands" className="max-w-md bg-white bottom-0 container mx-auto flex fixed flex-wrap pb-6">
        <div className={`w-full mx-2 mb-2 ${scanOnly ? "hidden" : ""}`}>
          <div className="flex columns-2 items-center pt-4">
            <div className="w-2/5 mr-4 mx-auto">
              <Link to={slotOneFilled ? `/items/${slotOne}` : ""} className={`bg-gray-200 rounded-xl aspect-square flex justify-center items-center font-bold text-white`}>
                {slotOneFilled ? s1Exists ? s1Image : "loading" : <div className="" />}
              </Link>
              <div className="flex mt-1">
                <div className="flex mx-auto max-w-full">
                  {s1Icon}
                  <p className="ml-1 text-lg mx-auto truncate text-ellipsis w-full whitespace-nowrap flex-nowrap overflow-hidden block">
                    {slotOneFilled ? s1Exists ? s1Name : "" : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-2/5 ml-4 mx-auto">
              <Link to={slotTwoFilled ? `/items/${slotTwo}` : ""} className={`bg-gray-200 rounded-xl aspect-square flex justify-center items-center font-bold text-white`}>
                {slotTwoFilled ? s2Exists ? s2Image : "loading" : <div />}
              </Link>
              <div className="flex mt-1 w-full">
                <div className="flex mx-auto max-w-full">
                  {s2Icon}
                  <p className="ml-1 text-lg mx-auto truncate text-ellipsis w-full whitespace-nowrap flex-nowrap overflow-hidden block">
                    {slotTwoFilled ? s2Exists ? s2Name : "" : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-gray-400 border-dashed mt-2 mb-2" />

          <div className="text-center text-lg">
            {linkMessage}
          </div>
        </div>


        <div className="w-full flex container flex-col-2">
          <Link onClick={() => {
            scanner?.stop()
            state.slot1("")
            state.slot2("")
            setScanning(false)
          }} to="/?refresh" className={`${canLink ? "w-1/2" : "w-full"} text-white w-full font-bold py-4 mt-2 mx-2 rounded-xl my-auto bg-[#E04F23] shadow-[0_0px_6px_2px_rgba(255,0,0,0.5)]`}>
            <button className="flex mx-auto h-10">
              <svg className="block my-auto h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                <g id="Group_45" data-name="Group 45" transform="translate(3 3)">
                  <g id="Group_44" data-name="Group 44" clipPath="url(#clipPath)">
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="10.542" cy="10.542" r="10.542" transform="translate(0.458 0.458)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                    <line id="Line_7" data-name="Line 7" x2="8.25" y2="8.25" transform="translate(6.875 6.875)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                    <line id="Line_8" data-name="Line 8" y1="8.25" x2="8.25" transform="translate(6.875 6.875)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                  </g>
                </g>
              </svg>
              <span className="mx-auto ml-1 block text-2xl my-auto">Cancel</span>
            </button>
          </Link>

          <Link onClick={(e) => {
            e.preventDefault()
            HandleLink()
          }} to="#" className={`${canLink ? "" : "hidden"} w-1/2 text-white w-full font-bold py-4 mt-2 mx-2 rounded-xl my-auto bg-[#087F79] shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]`}>
            <button className="flex mx-auto h-10">
              <svg className={`animate-spin my-auto mr-3 h-5 w-5 text-white ${linking ? "" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <div className={`flex my-auto ${linking ? "hidden" : ""}`}>
                {iconLink}
              </div>
              <span className="mx-auto ml-1 block text-2xl my-auto">Link</span>
            </button>
          </Link>

        </div>
      </section >
    </div >);
}