import React from 'react';

type Props = {
  title: string,
  children: JSX.Element,
}

const Layout = ({ title, children }: Props) => (
  <>
    <div className="flex w-full max-w-full h-screen justify-center items-center md:bg-yellow-200 md:overflow-y-hidden">
      <div className="flex-grow hidden md:block max-w-4/5 w-4/5">
        <div className="m-auto w-4/5 min-w-4/5 text-center">
          <h1 className="font-bold text-4xl">Get Started</h1>
          <div className="ml-2 mt-6 text-3xl w-full">
            <ol>
              <a href="https://sqrrl-store.square.site/product/sqrrl-welcome-kit/1">
                <li>
                  <div className="mt-4">1. <span className="underline">Get Free Labels</span></div>
                </li>
              </a>
              <li>
                <div className="mt-4">2. SQRRL Something</div>
              </li>
              <li>
                <div className="mt-4">3. ... That's It!</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="mx-auto h-screen flex md:border-4 md:border-black md:rounded-2xl flex-col md:flex-none bg-white max-w-md">
        <div className="h-7 bg-black w-full min-w-full flex-row hidden md:block md:rounded-t-xl">
          <div className="mx-auto py-1 my-1 m-0 h-4 w-4 rounded-full bg-gray-600 ">
            <div className="mx-auto p-0 h-2 w-2 rounded-full bg-gray-800" />
          </div>
        </div>
        {children}
      </div>
      <div className="flex-grow hidden md:block max-w-4/5 w-4/5 text-center">
        <div className="m-auto w-4/5 min-w-4/5">
          <h1 className="font-bold text-4xl">Learn How To SQRRL! ⬇️</h1>
          <a href="https://youtu.be/9w1DDwnFWLM">
            <img src="/video.jpg" alt="youtube" className="mx-auto mt-4" />
          </a>
        </div>
      </div>
    </div>
  </>
)

export default Layout;