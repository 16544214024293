import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import common from "../common";
import loader from "../components/loader";
import TopNav from "../components/topnav";

const iconBack = <svg xmlns="http://www.w3.org/2000/svg" className="block my-auto h-6 w-6" viewBox="0 0 22 17.805">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_73" data-name="Rectangle 73" width="22" height="17.805" fill="none" stroke="#087f79" strokeWidth="1" />
    </clipPath>
  </defs>
  <g id="Group_53" data-name="Group 53" transform="translate(0 0)">
    <g id="Group_52" data-name="Group 52" transform="translate(0 0)" clipPath="url(#clip-path)">
      <path id="Path_71" data-name="Path 71" d="M1.414,12.707H9.307c7.216,0,12.793,3.672,12.793,10.887v-.272" transform="translate(-0.644 -5.79)" fill="none" stroke="#087f79" strokeMiterlimit="10" strokeWidth="2" />
      <path id="Path_72" data-name="Path 72" d="M7.947.707,1.414,7.239l6.532,6.532" transform="translate(-0.644 -0.322)" fill="none" stroke="#087f79" strokeMiterlimit="10" strokeWidth="2" />
    </g>
  </g>
</svg>

const iconEdit = <svg className="h-6 w-6 my-auto" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32.2159 4.78412C31.522 4.09047 30.5811 3.70081 29.6 3.70081C28.6189 3.70081 27.6779 4.09047 26.9841 4.78412L12.95 18.8182V24.05H18.1818L32.2159 10.0159C32.9095 9.32206 33.2992 8.38112 33.2992 7.40002C33.2992 6.41891 32.9095 5.47797 32.2159 4.78412Z" fill="#fff" />
  <path fillRule="evenodd" clipRule="evenodd" d="M3.7 11.1C3.7 10.1187 4.08982 9.17761 4.7837 8.48373C5.47759 7.78984 6.4187 7.40002 7.4 7.40002H14.8C15.2906 7.40002 15.7612 7.59493 16.1081 7.94188C16.4551 8.28882 16.65 8.75937 16.65 9.25002C16.65 9.74067 16.4551 10.2112 16.1081 10.5582C15.7612 10.9051 15.2906 11.1 14.8 11.1H7.4V29.6H25.9V22.2C25.9 21.7094 26.0949 21.2388 26.4419 20.8919C26.7888 20.5449 27.2593 20.35 27.75 20.35C28.2406 20.35 28.7112 20.5449 29.0581 20.8919C29.4051 21.2388 29.6 21.7094 29.6 22.2V29.6C29.6 30.5813 29.2102 31.5224 28.5163 32.2163C27.8224 32.9102 26.8813 33.3 25.9 33.3H7.4C6.4187 33.3 5.47759 32.9102 4.7837 32.2163C4.08982 31.5224 3.7 30.5813 3.7 29.6V11.1Z" fill="#fff" />
</svg>




function imageOrEmpty(photo: any): string {
  if (typeof photo == 'undefined' || photo == null) {
    return common.png404
  } else {
    if (photo.square) {
      return photo.square
    } else {
      return common.png404
    }
  }
}

export default function DetailPage() {
  const { itemId } = useParams()
  const { loading, error, data, refetch } = useQuery(common.q.ITEM_QUERY, { variables: { id: itemId } })
  const childrenResponse = useQuery(common.q.CHILDREN_QUERY, { variables: { parentItemId: itemId } })

  const refetchAll = async () => {
    refetch()
    childrenResponse.refetch()
  }

  if (loading) {
    return loader
  }

  if (error) {
    console.log(error)
    return <div>Error</div>
  }

  let hasChildren = false
  let children = []
  if (childrenResponse.data && childrenResponse.data.itemsAt) {
    children = childrenResponse.data.itemsAt
    hasChildren = children.length > 0
  }

  const item = data.item

  const hasPhoto = item.detail.photo && item.detail.photo.full
  const primaryPhoto = hasPhoto ? <img alt="" className="w-full rounded-2xl" src={item.detail.photo.full} />
    : <img alt="" className="w-full" src={common.png404} />

  const hasParent = !(typeof item.parent == 'undefined' || item.parent == null)
  const parent = hasParent ? item.parent : null

  const hasGrandparent = hasParent && !(typeof parent.parent == 'undefined' || parent.parent == null)
  const grandparent = (hasParent && hasGrandparent) ? parent.parent : null

  let ancestors = []
  if (hasParent) {
    ancestors.push(parent)
    if (hasGrandparent) {
      ancestors.push(grandparent)
    }
  }

  return (
    <div className="max-w-md mx-auto pb-40">
      <TopNav />
      <PullToRefresh onRefresh={refetchAll} className="md:pb-40">
        <div>
          <section className="w-full px-4 mt-2">
            <h1 className="text-4xl underline">{item.detail.name}</h1>
            <h3 className="my-1 leading-tight font-medium">{item.type} #{item.id} {item.externalCode ? `(${item.externalCode})`:""}</h3>
          </section>

          <div className="mt-2 px-4 mb-4">
            {primaryPhoto}
          </div>

          <div className="container grid grid-cols-2 gap-4 mx-auto px-4 text-center font-bold text-white text-lg">
            {ancestors.map((item) =>
              <div key={item.id}>
                <Link to={`/items/${item.id}`}>
                  {item.type === "container" ?
                    <div className="w-full bg-[#087F79] rounded-t-xl">Container</div> :
                    <div className="w-full bg-[#087F79] rounded-t-xl">Location</div>
                  }
                  <img alt="" className="w-full rounded-b-xl" src={item.detail.photo.full ? item.detail.photo.full : common.png404} />
                </Link>
              </div>
            )}
          </div>

          {/* {item.detail.keywords.length > 1 ?
            <section className="w-full px-4 mt-2">
              <h3 className="my-1 leading-tight font-medium text-center">keywords: {item.detail.keywords}</h3> </section> : ""}
 */}
          {hasChildren ?
            <section id="results" className="px-4">
              <h1 className="text-4xl underline">Contents</h1>
              <div className="container grid grid-cols-3 gap-1 mx-auto mt-4">
                {children.map((item: any) =>
                  <Link key={item.id} to={`/items/${item.id}`} className="block">
                    <div className="w-full rounded">
                      <img src={imageOrEmpty(item.detail.photo)} alt="obj" className="rounded-xl" />
                    </div>
                  </Link>
                )}
              </div>
            </section> : ""}
        </div>
      </PullToRefresh>
      <div className="md:h-screen"/>        
      <section id="commands" className="max-w-md bg-white bottom-0 container mx-auto flex sticky flex-wrap pb-6 shadow-[0_0px_6px_4px_rgba(255,255,255,1)] md:shadow-none md:rounded-b-2xl md:border-b-4 md:border-black">
        <div className="w-full flex container flex-col-2">
          <Link to="/?refresh" className={`w-1/2 text-white font-bold py-4 mt-2 mx-2 rounded-xl my-auto shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]`}>
            <button className="flex mx-auto h-10">
              {iconBack}
              <span className="mx-auto ml-1 block text-2xl my-auto text-[#087F79]">Back</span>
            </button>
          </Link>

          <Link to={`/items/${itemId}/edit`} className={`w-1/2 text-white w-full font-bold py-4 mt-2 mx-2 rounded-xl my-auto bg-[#087F79] shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]`}>
            <button className="flex mx-auto h-10">
              {iconEdit}
              <span className="mx-auto ml-1 block text-2xl my-auto">Edit</span>
            </button>
          </Link>
        </div>
      </section>

    </div>
  );
}


