import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh';
import common from "../common";
import loader from "../components/loader";
import TopNav from "../components/topnav";
import introPhoto from './qr.jpg';


export default function SearchPage() {
  const response = useQuery(common.q.ITEMS_QUERY)
  const [searchTerm, setSearchTerm] = useState("")
  const { loading, data, error, refetch } = response
  const navigate = useNavigate()
  const location = useLocation()
  if (location.search.includes("refresh")) {
    refetch().finally(() => navigate('/'))
  }

  if (loading) {
    return loader
  }

  if (typeof error !== "undefined" && error != null) {
    navigate('/login')
    return <div>Logging in</div>
  }

  var items: Array<any> = data.items
  const originalLength = items.length

  items = items.filter((i: any) => {
    const n: String = i.detail.name + " " + i.detail.keywords + " " + i.id
    return n.toLowerCase().includes(searchTerm.toLowerCase())
  })

  const noResults = originalLength > 0 && items.length === 0


  return (<div className="max-w-md mx-auto md:h-full md:pb-40">
    <TopNav showSearch onSearch={(s) => setSearchTerm(s)} />
    {
      items.length === 0 ?
        noResults ? <div className="w-full flex mt-14">
          <div className="mx-auto w-2/3">
            <div className="border-r border-b border-l border-t border-gray-400 bg-white rounded-lg p-4 flex flex-col justify-between leading-normal">
              <div className="">
                <p className="flex items-center text-center">
                </p>
                <div className="text-gray-900 font-bold text-2xl mb-2 text-center">No results</div>
                <p className="text-gray-700 text-clip text-center text-xl">No matches for "{searchTerm}"</p>
              </div>
            </div>
          </div>
        </div>
          :
          <div className="w-full flex">
            <div className="mx-auto w-4/5">
              <img src={introPhoto} alt="intro" className="w-full flex-none bg-cover rounded-t overflow-hidden" title="intro">
              </img>
              <div className="border-2 bg-white p-4 flex flex-col justify-between leading-normal">
                <div className="text-gray-900 font-bold text-xl mb-2 text-center">Start scanning!</div>
                <p className="text-gray-700 text-clip text-center">Apply labels to Containers, Locations and Items.</p>
                <p className="text-gray-700 text-clip text-center mt-2">
                  <a href="https://sqrrl-store.square.site/product/sqrrl-welcome-kit/1" className="underline font-semibold">
                    Get Free Labels
                  </a> or
                  <a href="/labels00.png" className="underline font-semibold">
                    Print Your Own
                  </a>.</p>
                <p className="text-gray-700 text-clip text-center mt-2">Tap <strong>SQRRL Something</strong> to begin scanning.</p>
              </div>
            </div>
          </div> :
        <PullToRefresh onRefresh={refetch}>
          <div className="mx-2">
            <section id="results" className="container grid grid-cols-2 gap-2 mx-auto mb-32">
              {items.map((item: any) =>
                <Link key={item.id} to={`/items/${item.id}`} className="block">
                  <div className="w-full rounded">
                    <img className="rounded-md w-full" src={common.imageOrEmpty(item.detail.photo)} alt="obj" />
                  </div>
                </Link>
              )}
            </section>
          </div>
        </PullToRefresh>
    }

    <div className="h-screen"></div>
    <section id="commands" className="max-w-md bg-white py-2 bottom-0 container mx-auto flex flex-col-3 from-white md:px-2 sticky pb-6 shadow-[0_0px_6px_4px_rgba(255,255,255,1)] md:shadow-none md:rounded-b-2xl md:border-b-4 md:border-b-black">
      <Link to="/scan" className="text-white w-full font-bold py-4 mx-2 rounded-xl mx-auto my-auto bg-[#087F79] inline-flex z-10 shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]">
        <button className="flex mx-auto my-auto h-10">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 my-auto" viewBox="0 0 46 45">
            <defs>
              <clipPath id="clipPath">
                <rect id="Rectangle_41" data-name="Rectangle 41" width="46" height="45" transform="translate(-4.412 -3.511)" fill="none" stroke="#fff" strokeWidth="3" />
              </clipPath>
            </defs>
            <g id="Group_22" data-name="Group 22" transform="translate(4.412 3.511)">
              <line id="Line_4" data-name="Line 4" x2="11.124" transform="translate(13.11 18.307)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <line id="Line_5" data-name="Line 5" y1="11.124" transform="translate(18.672 12.745)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <g id="Group_21" data-name="Group 21" transform="translate(0 0)">
                <g id="Group_20" data-name="Group 20" clipPath="url(#clipPath)">
                  <path id="Path_18" data-name="Path 18" d="M42.61,14.11V1H29.5" transform="translate(-6.061 -0.571)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                  <path id="Path_19" data-name="Path 19" d="M14.11,1H1V14.11" transform="translate(-0.205 -0.571)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                  <path id="Path_20" data-name="Path 20" d="M1,29.5V42.61H14.11" transform="translate(-0.205 -6.426)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                  <path id="Path_21" data-name="Path 21" d="M29.5,42.61H42.61V29.5" transform="translate(-6.061 -6.426)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                </g>
              </g>
            </g>
          </svg>
          <span className="mx-auto ml-1 block text-2xl my-auto">SQRRL Something</span>
        </button>
      </Link>
    </section>
  </div>);
}