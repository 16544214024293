import { gql } from "@apollo/client"
import assets from "./assets"

const isDeployed = window.location.hostname.includes('sqrrl')

const localLoginURI = 'http://' + window.location.hostname + ':8080/login'
const deployedLoginURI = 'https://' + window.location.hostname + '/api/login'
const finalLoginURI = isDeployed ? deployedLoginURI : localLoginURI

const localLogoutURI = 'http://' + window.location.hostname + ':8080/logout'
const deployedLogoutURI = 'https://' + window.location.hostname + '/api/logout'
const finalLogoutURI = isDeployed ? deployedLogoutURI : localLogoutURI

function envPath(path:String):string {
  const base = isDeployed ? 'https://' + window.location.hostname + '/api' : 'http://' + window.location.hostname + ':8080'
  return base + path
}

const ITEMS_QUERY = gql`
query AllItems {
  items {
    __typename
    id
    ... on Item { 
    type
    externalCode
    detail {
      name
      keywords
      photo {
        __typename
        square
        full
      }
    }
    parent {
      __typename
      id
      type
      externalCode
      detail {
        name
        keywords
        photo {
          __typename
          square
          full
        }
      }      
      parent {
      __typename
      id
      type
      externalCode
      detail {
        name
        keywords
        photo {
          __typename
          square
          full
        }
      }      
    }
    }
    }
  }
}
`

const ITEM_QUERY = gql`
query Item($id: ID!) {
  item(id: $id) {
    __typename
    id
    ... on Item {
    type
    externalCode
    detail {
      __typename
      name
      keywords
      photo {
        __typename
        square
        full
      }
    }
    parent {
      __typename
      id
      type
      externalCode
      detail {
        __typename
        name
        keywords
        photo {
          __typename
          square
          full
        }
      }      
      parent {
      __typename
      id
      type
      externalCode
      detail {
        __typename
        name
        keywords
        photo {
          __typename
          square
          full
        }
      }      
    }
    }
    }
  }
}
`;

const CHILDREN_QUERY = gql`
query Children($parentItemId: ID!) {
  itemsAt(parentItemId: $parentItemId) {
    id
    detail {
      name
      keywords      
      photo {
				square
      }
    }
  }
}
`

const UPDATE_ITEM = gql`
mutation UpdateItem($itemId: ID!, $name: String!, $keywords: String!, $itemType: String!) {
  updateItem(itemId: $itemId, name: $name, keywords: $keywords, itemType: $itemType) {
    id,
    detail {name, keywords}
  }
}
`;

const ADD_ITEM = gql`
mutation AddItem($itemId: ID!, $name: String!, $keywords: String!, $itemType: String!) {
  addItem(itemId: $itemId, name: $name, keywords: $keywords, itemType: $itemType) {
    id,
    detail {name, keywords}
  }
}
`;

const LINK_ITEM = gql`
mutation Link($firstItemId: ID!, $secondItemId: ID!) {
  link(firstItemId: $firstItemId, secondItemId: $secondItemId) {
    id
  } 
}
`;

const FORGET_ITEM = gql`
mutation Forget($itemId: ID!) {
  forgetItem(itemId: $itemId) 
}
`;

function imageOrEmpty(photo: any): string {
  if (typeof photo == 'undefined' || photo == null) {
    return common.png404
  } else {
    if (photo.full) {
      return photo.full
    } else {
      return common.png404
    }
  }
}

const common = {
  imageOrEmpty: imageOrEmpty,
  isDeployed: isDeployed,
  png404: assets.png404,
  q: {
    ITEM_QUERY,
    CHILDREN_QUERY,
    ADD_ITEM,
    UPDATE_ITEM,
    FORGET_ITEM,
    LINK_ITEM,
    ITEMS_QUERY
  },
  urls : {
    LOGOUT : finalLogoutURI,
    LOGIN: finalLoginURI,
    BEGIN_MAGIC: envPath('/begin_magic'),
    REDEEM_MAGIC: envPath('/redeem_magic'),
  }
}

export default common