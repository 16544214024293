import { makeVar } from "@apollo/client";

const state = {
  scanning: makeVar(false),
  slot1: makeVar(""),
  slot2: makeVar(""),
}

export default state

