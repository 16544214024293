import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { FormEvent, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import common from "../common";
import loader from "../components/loader";
import state from "../state";

const iconDone = <svg xmlns="http://www.w3.org/2000/svg" className="block my-auto h-8 w-8" width="20.202" height="27" viewBox="0 0 20.202 27">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_71" data-name="Rectangle 71" width="20.202" height="27" fill="none" stroke="#fff" strokeWidth="1" />
    </clipPath>
  </defs>
  <g id="Group_48" data-name="Group 48" transform="translate(0 0)">
    <path id="Path_68" data-name="Path 68" d="M11.124,24.283l3.657,3.657,4.233-7.327" transform="translate(-4.898 -9.064)" fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
    <g id="Group_49" data-name="Group 49" transform="translate(0 0)">
      <g id="Group_48-2" data-name="Group 48" clipPath="url(#clip-path)">
        <path id="Path_69" data-name="Path 69" d="M1,4.481V22.438a4.49,4.49,0,0,0,4.489,4.489h10.1a4.49,4.49,0,0,0,4.489-4.489V6.726H3.137A2.137,2.137,0,0,1,1,4.588,1.857,1.857,0,0,1,2.478,2.77L17.835,1.114V6.726" transform="translate(-0.439 -0.489)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
      </g>
    </g>
  </g>
</svg>

const uploadIcon = <svg className="w-20 h-20" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.00007 15C5.01509 15.0011 4.06433 14.6387 3.32994 13.9823C2.59556 13.3259 2.12919 12.4216 2.02018 11.4427C1.91118 10.4637 2.1672 9.47899 2.73921 8.67711C3.31121 7.87523 4.15897 7.3126 5.12007 7.097C4.84199 5.80009 5.0905 4.44583 5.81092 3.33215C6.53134 2.21846 7.66467 1.43658 8.96157 1.1585C10.2585 0.880417 11.6127 1.12892 12.7264 1.84934C13.8401 2.56976 14.622 3.70309 14.9001 5H15.0001C16.24 4.99875 17.4362 5.45828 18.3564 6.28937C19.2766 7.12046 19.8552 8.26383 19.9798 9.4975C20.1044 10.7312 19.7662 11.9671 19.0308 12.9655C18.2954 13.9638 17.2152 14.6533 16.0001 14.9M14.0001 12L11.0001 9M11.0001 9L8.00007 12M11.0001 9V21" stroke="#3F3F46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const localURI = 'http://' + window.location.hostname + ':8080/upload'
const deployedURI = 'https://' + window.location.hostname + '/api/upload'
const isDeployed = window.location.hostname.includes('sqrrl')
const finalURI = isDeployed ? deployedURI : localURI

export default function EditPage() {
  const { itemId } = useParams()
  const { loading, data, refetch, error } = useQuery(common.q.ITEM_QUERY, { variables: { id: itemId } })
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateItem] = useMutation(common.q.UPDATE_ITEM);
  const [addItem] = useMutation(common.q.ADD_ITEM);
  const [forgetItem] = useMutation(common.q.FORGET_ITEM);
  const client = useApolloClient()


  let navigate = useNavigate()

  var [name, setName] = useState("")
  var [keywords, setKeywords] = useState("")
  var [itemType, setItemType] = useState("");

  if (loading) {
    return loader
  }

  let isNew = false
  if (!(error === undefined)) {
    isNew = true
  }

  var item: any
  if (isNew) {
    item = { detail: { photo: null, keywords: "" } }
  } else {
    item = data.item
    if (keywords === "" && item.detail.keywords !== "") {
      setKeywords(item.detail.keywords)
    }
  }

  const hasPhoto = !(item.detail.photo == null)

  if (name === "") {
    if (item.detail.name) {
      setName(item.detail.name)
    }
  }

  if (itemType === "") {
    if (isNew) {
      setItemType("item")
    } else {
      if (item.type) {
        setItemType(item.type)
      }
    }
  }

  async function HandleSave() {
    setSaving(true)
    if (isNew) {
      await addItem({ variables: { itemId, name, keywords, itemType } })
      refetch()
        .then(() => setTimeout(() => document.getElementsByName('photo')[0].click(), 500))
        .finally(() => setSaving(false))
    } else {
      await updateItem({ variables: { itemId: item.id, name, keywords, itemType } });
      refetch()
        .then(() => navigate(-1))
        .catch((e) => alert(e))
        .finally(() => setSaving(false))
    }
  }

  async function HandleForget() {
    forgetItem({ variables: { itemId: item.id } })
      .then(() => client.clearStore())
      .finally(() => navigate("/"));
  }

  async function HandlePhoto() {
    if (isNew) {
      setSaving(true)
      await addItem({ variables: { itemId, name, keywords, itemType } })
      refetch()
        .then(() => setTimeout(() => document.getElementsByName('photo')[0].click(), 250))
        .finally(() => setSaving(false))
    } else {
      setTimeout(() => document.getElementsByName('photo')[0].click(), 250)
    }
  }

  function HandleUpload(e: FormEvent) {
    setUploading(true)
    const files = (e.target as HTMLInputElement).files
    if (!files || files.length !== 1) {
      throw new Error('file html input element not found')
    }

    const formData = new FormData()
    const file = files[0]
    formData.append("itemId", "" + item.id)
    formData.append("photo", file)

    fetch(finalURI, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    })
      .catch((e) => {
        throw e
      })
      .then(() => {
        refetch()
      }).finally(() => setUploading(false))
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <form className="bg-white rounded px-4 pt-4 pb-8 mb-40">
        <h3 className="mb-4 leading-tight font-medium">{isNew ? "code" : item.type} #{isNew ? itemId : item.id} {isNew ? "(new)" : ""}</h3>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input autoFocus onFocus={(e) => e.target.select()} className="text-2xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="nameInput" type="text" placeholder="name" defaultValue={item.detail.name} onChange={(e) => { setName(e.target.value) }} />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="keywords">
            Tags
          </label>
          <input className="lowercase text-2xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="keywordsInput" type="text" placeholder="space separated keywords" defaultValue={item.detail.keywords} onChange={(e) => setKeywords(e.target.value)} />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="keywords">
            Type
          </label>
          <div className="font-bold bg-white border-2 border-[#087F79] rounded-full w-full mx-auto text-[#087F79] text-center">
            <div className={`mx-auto capitalize w-1/3 inline-block p-2 rounded-l-full ${itemType === "item" ? "bg-[#087F79] text-white" : ""}`} onClick={() => setItemType("item")}>item</div>
            <div className={`mx-auto capitalize w-1/3 inline-block p-2 ${itemType === "container" ? "bg-[#087F79] text-white" : ""}`} onClick={() => setItemType("container")}>container</div>
            <div className={`mx-auto capitalize w-1/3 inline-block p-2 rounded-r-full ${itemType === "location" ? "bg-[#087F79] text-white" : ""}`} onClick={() => setItemType("location")}>location</div>
          </div>
        </div>
        <div className={`mb-6`}>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="keywords">
            Primary Photo
          </label>
          {(hasPhoto && !uploading) ? <img className="rounded-2xl" alt="" onClick={() => document.getElementsByName('photo')[0].click()} src={item.detail.photo.full} />
            : <div className={`${uploading ? "animate-spin" : ""}`} onClick={HandlePhoto}>{uploadIcon}</div>}
        </div>

        <div className={`rounded-xl text-[#E04F23] shadow-[0_0px_6px_2px_rgba(255,0,0,0.5)] py-3 ${isNew ? "hidden" : ""}`}>
          <button className="flex mx-auto h-10 py" onClick={(e) => {
            e.preventDefault()
            if (window.confirm('Remove from SQRRL?')) {
              HandleForget()
            }
          }}>
            <svg className="block my-auto h-7 w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20">
              <path d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z" stroke="#E04F23" fill="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="mx-auto ml-1 block text-2xl my-auto capitalize">Forget This {itemType}</span>
          </button>
        </div>
      </form>
      
      <div className="h-screen"></div>
      <section id="commands" className="max-w-md bg-white bottom-0 container mx-auto flex sticky flex-wrap pb-6 shadow-[0_0px_6px_4px_rgba(255,255,255,1)] md:shadow-none md:rounded-b-2xl md:border-b-4 md:border-black">

        <div className="w-full flex container flex-col-2">
          <Link to="#" onClick={() => {
            state.slot1("")
            state.slot2("")
            navigate(-1)
          }} className={`w-1/2 text-white font-bold py-4 mt-2 mx-2 rounded-xl my-auto bg-[#E04F23] shadow-[0_0px_6px_2px_rgba(255,0,0,0.5)]`}>
            <button className="flex mx-auto h-10">
              <svg className="block my-auto h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                <g id="Group_45" data-name="Group 45" transform="translate(3 3)">
                  <g id="Group_44" data-name="Group 44" clipPath="url(#clipPath)">
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="10.542" cy="10.542" r="10.542" transform="translate(0.458 0.458)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                    <line id="Line_7" data-name="Line 7" x2="8.25" y2="8.25" transform="translate(6.875 6.875)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                    <line id="Line_8" data-name="Line 8" y1="8.25" x2="8.25" transform="translate(6.875 6.875)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
                  </g>
                </g>
              </svg>
              <span className="mx-auto ml-1 block text-2xl my-auto">Cancel</span>
            </button>
          </Link>

          <Link onClick={(e) => {
            e.preventDefault()
            HandleSave()
          }} to="#" className={`${true ? "" : "hidden"} w-1/2 text-white w-full font-bold py-4 mt-2 mx-2 rounded-xl my-auto bg-[#087F79] shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]`}>
            <button disabled={saving} className="flex mx-auto h-10">
              <svg className={`animate-spin my-auto mr-3 h-5 w-5 text-white ${saving ? "" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <div className={`my-auto ${saving ? "hidden" : ""}`}>
                {iconDone}
              </div>
              <span className="mx-auto ml-1 block text-2xl my-auto">Done</span>
            </button>
          </Link>

        </div>
      </section>

      <section className="hidden">
        <form encType="multipart/form-data" method="post">
          <input type="file" name="photo" accept="image/jpg, image/jpeg" onChange={HandleUpload} capture></input>
        </form>
      </section>
    </div>
  );
}