import {
  ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { Route, Routes, unstable_HistoryRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import './index.css';
import DetailPage from "./pages/detail";
import EditPage from "./pages/edit";
import LoginPageMagicLink from "./pages/login";
import ScanPage from "./pages/scan";
import SearchPage from "./pages/search";
import reportWebVitals from './reportWebVitals';

const isDeployed = window.location.hostname.includes('sqrrl')

Sentry.init({
  dsn: "https://0683823c17dc4c8f86dd3e34abbaf76e@o1148469.ingest.sentry.io/6219946",
  integrations: [new BrowserTracing()],
  environment: isDeployed ? "production" : "development",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactGA.initialize('G-RVSMP3TC98')

const localURI = 'http://' + window.location.hostname + ':8080/query'
const deployedURI = 'https://' + window.location.hostname + '/api/query'
const finalURI = isDeployed ? deployedURI : localURI

const link = createHttpLink({
  uri: finalURI,
  credentials: 'include'
});

const client = new ApolloClient<NormalizedCacheObject>({
  connectToDevTools: true,
  cache: new InMemoryCache(),
  link
});

const history = createBrowserHistory({ window });
history.listen(({ action, location }) => {
  ReactGA.send("pageview")
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={history}>
        <Layout title="buff">
          <Routes>
            <Route path="/" element={<SearchPage />} />
            <Route path="/items/:itemId" element={<DetailPage />} />
            <Route path="/items/:itemId/edit" element={<EditPage />} />
            <Route path="/scan/:itemId" element={<ScanPage />} />
            <Route path="/scan" element={<ScanPage />} />
            <Route path="login" element={<LoginPageMagicLink />} />
            <Route path="login/:linkCode" element={<LoginPageMagicLink />} />
          </Routes>
        </Layout>

      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
