import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import common from '../common';

export default function LoginPageMagicLink() {
  const { linkCode } = useParams()
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [code, setCode] = useState("")
  const [readyForCode, setReadyForCode] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const handleCodeCallback = useCallback(HandleCode, [code, navigate])

  // when clicked from email
  useEffect(() => {
    if (linkCode && linkCode.length > 1) {
      setCode(linkCode)
      handleCodeCallback()
    }
  }, [linkCode, setCode, handleCodeCallback])

  function HandleCode() {
    setLoading(true)
    setErrorMessage("")
    const formData = new FormData()
    formData.append("magicCode", code)
    fetch(common.urls.REDEEM_MAGIC, {
      method: 'POST',
      body: formData,
      redirect: 'manual',
      credentials: 'include'
    }).then((response) => {
      switch (response.status) {
        case 200:
          setTimeout(() => navigate('/?refresh'), 250)
          break
        case 401:
          setErrorMessage("try again")
          break
        default:
          setErrorMessage("try again")
      }
    }).catch((reason) => {
      setErrorMessage("unknown error")
    }).finally(() => setLoading(false))
  }

  function HandleLogin() {
    setLoading(true)
    setErrorMessage("")
    const formData = new FormData()
    formData.append("magicEmail", email)
    fetch(common.urls.BEGIN_MAGIC, {
      method: 'POST',
      body: formData,
      redirect: 'manual',
      credentials: 'include'
    }).then((response) => {
      switch (response.status) {
        case 200:
          setReadyForCode(true)
          break
        case 401:
          setErrorMessage("try again")
          break
        default:
          setErrorMessage("try again")
      }
    }).catch((reason) => {
      setErrorMessage("unknown error")
    }).finally(() => setLoading(false))
  }

  return (
        <section className="m-auto block">
          <div className="px-4 bg-white rounded mx-4 my-auto  flex-row">
            <div className="mb-4 text-center">
              <h2 className={`text-4xl font-bold transition-all ${readyForCode ? "animate-pulse" : ""}`}>
                {readyForCode ? "Check your inbox." : "Ready to SQRRL?"}                
              </h2>
              <div className="mt-4 underline font-semibold">(<a href="https://youtu.be/9w1DDwnFWLM">demo video</a>)</div>
              <div className="mt-4 text-xl">Magic Links use your e-mail inbox instead of passwords. You must provide an e-mail address to log in.</div>
            </div>
            <div className="mb-4">
              <h3 className={`text-red-400 text-xl font-semibold text-center ${errorMessage.length > 0 ? "" : "hidden"}`}>{errorMessage}</h3>
              <input autoFocus className={`text-center w-full text-2xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline  ${readyForCode ? "hidden" : ""}`}
                name="magicEmail"
                type="email" autoComplete="email"
                onChange={(e) => { setEmail(e.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    HandleLogin()
                  }
                }}
                id="email" placeholder="your@email.com" />
              <input autoFocus className={`text-center w-full uppercase text-2xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${readyForCode ? "" : "hidden"}`}
                name="magicCode"
                onChange={(e) => { setCode(e.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    HandleCode()
                  }
                }}
                id="codeInput" type="text" placeholder="abcde" />
            </div>
            <div className="text-center">
              <button disabled={loading} type="submit" onClick={HandleLogin} className={`inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#087F79] transition ease-in-out duration-150 cursor-not-allowed ${readyForCode ? "hidden" : ""}`}>
                <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${loading ? "" : "hidden"}`}
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="text-xl">send magic link</span>
              </button>
              <button disabled={loading} type="submit" onClick={HandleCode} className={`inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-[#087F79] transition ease-in-out duration-150 cursor-not-allowed ${readyForCode ? "" : "hidden"}`}>
                <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${loading ? "" : "hidden"}`}
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="text-xl">submit code</span>
              </button>
              <div className={`mt-6 text-sm ${readyForCode ? "hidden" : ""}`}><button className="text-xl underline" onClick={() => setReadyForCode(true)}>have a code?</button></div>
              <Link to="/?refresh" className="block underline text-sm mt-4 text-xl">back to app</Link>
            </div>
          </div>
        </section>
  );
}
