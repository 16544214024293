import { useApolloClient } from "@apollo/client";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import common from "../common";


const sqrrlIcon = <svg xmlns="http://www.w3.org/2000/svg" width="100" height="15.018" viewBox="0 0 100 15.018">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_90" data-name="Rectangle 90" width="100" height="15.018" transform="translate(0 0)" fill="none" />
    </clipPath>
  </defs>
  <g id="Group_89" data-name="Group 89" transform="translate(0 0)">
    <g id="Group_88" data-name="Group 88" transform="translate(0 0)" clipPath="url(#clip-path)">
      <path id="Path_107" data-name="Path 107" d="M15.272,6.876a10.849,10.849,0,0,0-2.357-.757Q11.656,5.863,10.6,5.73q-.205-.019-.937-.1T8.143,5.39a10.646,10.646,0,0,1-1.405-.36q-.615-.208-.615-.474a.467.467,0,0,1,.249-.417A1.8,1.8,0,0,1,7,3.922a5.426,5.426,0,0,1,.805-.076q.424-.009.747-.009a12.254,12.254,0,0,1,2.371.256,15.474,15.474,0,0,1,2.781.861.911.911,0,0,0,.527.076A.87.87,0,0,0,14.7,4.84l2.284-2.1a.363.363,0,0,0,.146-.341.466.466,0,0,0-.264-.322A17.381,17.381,0,0,0,13.135.722,19.073,19.073,0,0,0,8.729.258a21.979,21.979,0,0,0-2.9.2,10.812,10.812,0,0,0-2.708.7A5.6,5.6,0,0,0,1.117,2.511,3.05,3.05,0,0,0,.326,4.67,2.767,2.767,0,0,0,.751,6.213,3.951,3.951,0,0,0,1.878,7.34a6.668,6.668,0,0,0,1.6.776,16.725,16.725,0,0,0,1.8.5q.907.2,1.771.3t1.508.18q.673.095,1.317.152A10.934,10.934,0,0,1,11,9.4a3.256,3.256,0,0,1,.776.237.433.433,0,0,1,.293.388q0,.474-.673.71a6.224,6.224,0,0,1-1.961.237,12.248,12.248,0,0,1-2.9-.379A20.9,20.9,0,0,1,3.4,9.555,1.034,1.034,0,0,0,2.873,9.5a.874.874,0,0,0-.469.189L.151,11.79A.373.373,0,0,0,0,12.14a.418.418,0,0,0,.263.312,17.67,17.67,0,0,0,1.8.824,16.754,16.754,0,0,0,2.123.672,20.46,20.46,0,0,0,2.459.445,23.5,23.5,0,0,0,2.84.161A20.89,20.89,0,0,0,12.9,14.3a9.74,9.74,0,0,0,2.635-.786,4.552,4.552,0,0,0,1.713-1.354,3.184,3.184,0,0,0,.615-1.96,2.852,2.852,0,0,0-.746-2.017,5.731,5.731,0,0,0-1.844-1.306" transform="translate(0 -0.22)" fill="#087f79" />
      <path id="Path_108" data-name="Path 108" d="M309.885,13.061q-1.23-1-2.342-1.95a.253.253,0,0,1-.1-.312.605.605,0,0,1,.307-.274,5.365,5.365,0,0,0,1.259-.71,5.252,5.252,0,0,0,.966-.956A4.223,4.223,0,0,0,310.6,7.73a3.458,3.458,0,0,0,.219-1.2,3.7,3.7,0,0,0-.571-2.007,5.19,5.19,0,0,0-1.581-1.572,8.126,8.126,0,0,0-2.445-1.032,12.546,12.546,0,0,0-3.133-.369h-9.1a.9.9,0,0,0-.5.132.381.381,0,0,0-.2.322V14.954a.38.38,0,0,0,.206.322.9.9,0,0,0,.5.133h4.384a.9.9,0,0,0,.5-.133.382.382,0,0,0,.206-.322V11.887a.381.381,0,0,1,.2-.322.89.89,0,0,1,.5-.133h.641a1.987,1.987,0,0,1,.612.1,1.432,1.432,0,0,1,.5.256q.9.833,1.821,1.647t1.85,1.61a1.423,1.423,0,0,0,.512.256,2.133,2.133,0,0,0,.629.1h5.445a.38.38,0,0,0,.381-.18.257.257,0,0,0-.088-.331q-.966-.833-2.2-1.837m-5.064-5.908a1.707,1.707,0,0,1-.525.445,2.561,2.561,0,0,1-.7.265,3.317,3.317,0,0,1-.728.085h-3.088a.89.89,0,0,1-.495-.132.381.381,0,0,1-.2-.322V5.487a.381.381,0,0,1,.2-.322.89.89,0,0,1,.495-.133h3.088a2.924,2.924,0,0,1,1.034.161,1.98,1.98,0,0,1,.67.4,1.427,1.427,0,0,1,.349.492,1.24,1.24,0,0,1,.1.445,1,1,0,0,1-.2.625" transform="translate(-250.223 -1.321)" fill="#04b2a5" />
      <path id="Path_109" data-name="Path 109" d="M449.442,13.061q-1.23-1-2.342-1.95a.254.254,0,0,1-.1-.312.605.605,0,0,1,.307-.274,5.366,5.366,0,0,0,1.259-.71,5.253,5.253,0,0,0,.966-.956,4.221,4.221,0,0,0,.629-1.127,3.456,3.456,0,0,0,.22-1.2,3.7,3.7,0,0,0-.571-2.007,5.19,5.19,0,0,0-1.581-1.572,8.126,8.126,0,0,0-2.445-1.032,12.546,12.546,0,0,0-3.133-.369h-9.1a.9.9,0,0,0-.5.132.381.381,0,0,0-.2.322V14.954a.38.38,0,0,0,.206.322.9.9,0,0,0,.5.133h4.384a.9.9,0,0,0,.5-.133.381.381,0,0,0,.206-.322V11.887a.381.381,0,0,1,.2-.322.89.89,0,0,1,.5-.133h.641a1.988,1.988,0,0,1,.612.1,1.432,1.432,0,0,1,.5.256q.9.833,1.821,1.647t1.85,1.61a1.423,1.423,0,0,0,.512.256,2.133,2.133,0,0,0,.629.1h5.445a.38.38,0,0,0,.381-.18.257.257,0,0,0-.088-.331q-.966-.833-2.2-1.837m-5.064-5.908a1.707,1.707,0,0,1-.525.445,2.562,2.562,0,0,1-.7.265,3.317,3.317,0,0,1-.728.085h-3.088a.889.889,0,0,1-.495-.132.381.381,0,0,1-.2-.322V5.487a.381.381,0,0,1,.2-.322.89.89,0,0,1,.495-.133h3.088a2.923,2.923,0,0,1,1.034.161,1.98,1.98,0,0,1,.67.4,1.427,1.427,0,0,1,.349.492,1.24,1.24,0,0,1,.1.445,1,1,0,0,1-.2.625" transform="translate(-369.289 -1.321)" fill="#087f79" />
      <path id="Path_110" data-name="Path 110" d="M587.81,12.057a.9.9,0,0,0-.5-.132H578.5a.88.88,0,0,1-.512-.133.384.384,0,0,1-.19-.322V2a.383.383,0,0,0-.2-.322.9.9,0,0,0-.5-.132H572.7a.883.883,0,0,0-.512.132A.385.385,0,0,0,572,2V14.954a.385.385,0,0,0,.19.322.88.88,0,0,0,.512.132h14.609a.9.9,0,0,0,.5-.132.382.382,0,0,0,.2-.322V12.379a.382.382,0,0,0-.2-.322" transform="translate(-488.015 -1.321)" fill="#087f79" />
      <path id="Path_111" data-name="Path 111" d="M150.427,11.855,148.6,10.78a5.552,5.552,0,0,0,.679-.981,5.683,5.683,0,0,0,.688-2.736,4.9,4.9,0,0,0-.849-2.746,7.813,7.813,0,0,0-2.328-2.244A12.676,12.676,0,0,0,143.331.559,15.5,15.5,0,0,0,139.1,0h-.029a15.5,15.5,0,0,0-4.23.559,12.674,12.674,0,0,0-3.455,1.515,7.813,7.813,0,0,0-2.328,2.244,4.9,4.9,0,0,0-.849,2.746A5.683,5.683,0,0,0,128.9,9.8a6.338,6.338,0,0,0,2.108,2.234,11.551,11.551,0,0,0,3.586,1.515,18.925,18.925,0,0,0,3.889.53l.065.028c.183,0,.361,0,.539-.008s.356.007.539.008l.065-.028a18.925,18.925,0,0,0,3.889-.53,14.4,14.4,0,0,0,1.817-.593l3.286,1.921a1.057,1.057,0,0,0,1.444-.387l.688-1.191a1.057,1.057,0,0,0-.387-1.444M137.116,10.14a6.23,6.23,0,0,1-1.62-.729A3.635,3.635,0,0,1,134.4,8.35a2.322,2.322,0,0,1-.394-1.288,2.369,2.369,0,0,1,.394-1.3A3.7,3.7,0,0,1,135.5,4.686a5.86,5.86,0,0,1,1.62-.729,6.612,6.612,0,0,1,.942-.2,7.622,7.622,0,0,1,1.028-.068,7.082,7.082,0,0,1,1.97.265,5.86,5.86,0,0,1,1.62.729,3.7,3.7,0,0,1,1.095,1.079,2.369,2.369,0,0,1,.394,1.3,2.266,2.266,0,0,1-.226.988l-1.833-1.074-1.69-.991c-.748-.423-2.108.315-2.773.733a1,1,0,0,0-.08,1.65q.039.028.081.052l2.314,1.353a6.806,6.806,0,0,1-.957.365,6.386,6.386,0,0,1-.942.2,6.386,6.386,0,0,1-.942-.2" transform="translate(-109.385 0)" fill="#04b2a5" />
    </g>
  </g>
</svg>

const hamIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-5" viewBox="0 0 19 15">
  <g id="Group_145" data-name="Group 145" transform="translate(-99 -32.491)">
    <line id="Line_44" data-name="Line 44" x2="16" transform="translate(100.5 33.991)" fill="none" stroke="#087f79" strokeLinecap="round" strokeWidth="3" />
    <line id="Line_45" data-name="Line 45" x2="16" transform="translate(100.5 39.991)" fill="none" stroke="#087f79" strokeLinecap="round" strokeWidth="3" />
    <line id="Line_46" data-name="Line 46" x2="16" transform="translate(100.5 45.991)" fill="none" stroke="#087f79" strokeLinecap="round" strokeWidth="3" />
  </g>
</svg>

const glassIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_146" data-name="Group 146" transform="translate(-3603 2463)">
    <rect id="Rectangle_117" data-name="Rectangle 117" width="20" height="20" transform="translate(3603 -2463)" fill="none" />
    <circle id="Ellipse_1" data-name="Ellipse 1" cx="5.524" cy="5.524" r="5.524" transform="translate(3604.476 -2460.882)" fill="none" stroke="#087f79" strokeMiterlimit="10" strokeWidth="1.926" />
    <path id="Path_80" data-name="Path 80" d="M0,0,6.452,6.452" transform="translate(3614.548 -2451.452)" fill="none" stroke="#087f79" strokeLinecap="round" strokeWidth="2" />
  </g>
</svg>


interface Props {
  showSearch?: boolean;
  onSearch?(s: string): void;
}

export default function TopNav(props: Props) {
  const [hammy, setHammy] = useState(false)
  const client = useApolloClient()
  const navigate = useNavigate()
  const { showSearch, onSearch } = props

  function HandleLogout() {
    fetch(common.urls.LOGOUT, {
      method: 'GET',
      redirect: 'manual',
      credentials: 'include'
    }).then((response) => {
      switch (response.status) {
        case 200:
          setTimeout(() => navigate('/login'), 250)
          break
      }
    }).catch((reason) => {
      console.log("unknown error", reason)
    }).finally(() => {
      client.clearStore()
    })
  }

  return (
    <div className="flex mt-4 mb-4 max-w-md">
      <div className="flex">
        <button onClick={() => setHammy(true)} className="h-11 my-auto ml-4 mx-auto py-3 px-4 rounded-lg shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]">
          {hamIcon}
        </button>
      </div>
      {showSearch ?
        <div className="flex relative pl-1 text-gray-800 focus-within:text-gray-800">
          <input onChange={(e) => onSearch?.(e.target.value)} type="text"
            className=" text-3xl w-full ml-4 mr-2 rounded-xl px-3 py-1 border-2 outline-none border-[#087F7966] shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]" placeholder='search...'></input>
          <span className="absolute inset-y-0 right-0 flex items-center pr-4">
            <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
              {glassIcon}
            </button>
          </span>
        </div> : ""}

      {!showSearch ?
        <Link to="/?refresh" className="flex-none mx-auto items-center my-auto block">
          {sqrrlIcon}
        </Link> : ""
      }

      {!showSearch ?
        <Link to="/?refresh" className="flex-none">
          <button className="h-11 mr-4 mx-auto py-2 px-4 rounded-lg shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]">
            {glassIcon}
          </button>
        </Link> : ""}


      <div className={` max-w-md bg-white absolute top-0 left-0 w-4/5 h-screen z-50 shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)] px-4 ${hammy ? "" : "hidden"}`}>
        <div className="flex mt-4">
          <button onClick={() => setHammy(false)} className="text-[#087F79] ml-2 mx-auto py-3 w-14 font-bold px-4 rounded-lg shadow-[0_0px_6px_2px_rgba(8,127,121,0.5)]">
            X
          </button>
        </div>

        <div className="ml-2 mt-6 text-3xl">
          <Link to="/?refresh" onClick={() => setHammy(false)} >
            <h1 className="mt-4">Home</h1>
          </Link>
          <a href="https://youtu.be/9w1DDwnFWLM" target="_blank" rel="noreferrer">
            <h1 className="mt-4">How to SQRRL</h1>
          </a>
          <a href="https://sqrrl-store.square.site/product/sqrrl-welcome-kit/1">
            <h1 className="mt-4">Get Free Labels</h1>
          </a>
          {/* <Link to="#" onClick={()=>alert('coming soon')}>
            <h1 className="mt-4">Print Labels</h1>
          </Link> */}
          <Link to="#" onClick={() => alert('coming soon')}>
            <h1 className="mt-4">Invite Someone</h1>
          </Link>
          <a href="mailto:sqrrlapp@gmail.com">
            <h1 className="mt-4">E-Mail Support</h1>
          </a>
          <hr className="border-2  border-black mt-4"></hr>
          <Link to="#" onClick={HandleLogout}>
            <h1 className="mt-4">Log out</h1>
          </Link>
        </div>
      </div>
    </div>
  )
}

